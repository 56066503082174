import {Spin} from "antd";
import {Link} from "react-router-dom";
import Title from "antd/lib/typography/Title";
import React from "react";

export type PageProps = {
    pageHeader: string;
    loading?: boolean;
    children: React.ReactNode;
    learningLanguage?: string;
    nativeLanguage?: string;
    goBackLink?: string;
    goBackText?: string;
}

function Page({pageHeader, loading, children, learningLanguage, nativeLanguage, goBackLink, goBackText}: Readonly<PageProps>) {

    const link = goBackLink ?? `/lists/${nativeLanguage && learningLanguage ? `?learningLanguage=${learningLanguage}&nativeLanguage=${nativeLanguage}` : ""}`;
    const linkText = goBackText ?? "To Main Page";

    return loading ? (
        <Spin size="large" className="flex justify-center items-center"/>
    ) : (
        <div className="container mx-auto p-4">
            <Link className="underline text-blue-500 mb-5" to={link}>
                &#8249; {linkText}
            </Link>
            <div className="flex justify-center items-center flex-col gap-5">
                <Title level={2}>{pageHeader}</Title>
                {children}
            </div>
        </div>
    );
}

export default Page;
