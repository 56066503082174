import {LoadingOutlined,} from "@ant-design/icons";
import {AutoComplete, Button, Card, Col, Form, Input, message, Row, Spin} from "antd";
import axios from "axios";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {BASE_URL} from "../constant";
import {selectToken} from "../store/user/userSelector";
import {mapPos, toHeader} from "../utils/globalUtils";
import Page from "../components/Page";

export type PartOfSpeech = "NOUN" | "VERB" | "ADJECTIVE" | "OTHER";
export const PartOfSpeechValues = ["NOUN", "VERB", "ADJECTIVE", "OTHER"];

export const GenderValues = ["MASCULINE", "FEMININE", "NEUTER", "PLURAL"];

export type WordType =
    | "WORD"
    | "SENTENCE"
    | "SPREAD_VERB"
    | "PHRASE"
    | "GERMAN_PHRASAL_VERB"
    | "PHRASAL_VERB";

export interface WordTranslation {
    id?: number | string;
    wordFrom: IWord;
    wordTo: IWord;
}

export interface IWord {
    id: number | string;
    word: string;
    language: string;
    partOfSpeech: PartOfSpeech;
    imageUrl: string;
    soundUrl: string;
    wordDictionary: WordDictionary;
    wordType: WordType;
}

export interface IConjugation {
    id: number | string;
    infinitive: string;
    conjugation: IMappings;
}

export interface IMappings {
    gender?: string;
    mappings: {};
}

export interface WordDictionary {
    description: string;
    descriptionTranslation: string;
    synonyms: string[];
    conjugation: IConjugation;
}

export interface UserVocabulary {
    userVocabularyId?: number;
    wordTranslation?: WordTranslation;
    wordExampleTranslation?: WordExampleTranslation;
    vocabularyGroupId?: number;
}

export interface WordExampleTranslation {
    id?: number | string;
    example?: string;
    exampleTranslation?: string;
    soundUrl?: string;
    wordId?: number | string;
}

const WordDetails = () => {
    const {userVocabularyId} = useParams();
    const token = useSelector(selectToken);
    const [wordDetails, setWordDetails] = useState<UserVocabulary>({});
    const [possibleTranslations, setPossibleTranslations] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const fetch = async () => {
            setLoading(true);
            const res = await axios.get(
                `${BASE_URL}/vocabulary/getWord/${userVocabularyId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            );

            setWordDetails(res.data);
            setLoading(false);
        };

        fetch()
            .then((res) => console.log(res))
            .catch((err) => console.log(err));
    }, [token, userVocabularyId]);

    console.log("WordDetails", wordDetails);

    async function regenerate() {
        const res = await axios.post(
            `${BASE_URL}/vocabulary/regenerateExamples`,
            {
                userVocabularyId,
                vocabularyGroupId: wordDetails.vocabularyGroupId,
                difficulty: "EASY",
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        );
        console.log(res.data);
    }

    async function getPossibleTranslations() {
        const res = await axios.post(
            `${BASE_URL}/vocabulary/getPossibleTranslations`,
            {
                word: wordDetails.wordTranslation?.wordFrom.word,
                targetLanguage: wordDetails.wordTranslation?.wordTo.language,
                sourceLanguage: wordDetails.wordTranslation?.wordFrom.language,
                wordLimit: 100,
                isAutoComplete: false,
            },
            {
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "application/json",
                },
            },
        );
        setPossibleTranslations(res.data.possibleTranslations);
    }

    async function changeTranslation(e: any) {
        setPossibleTranslations([]);
        await axios.post(
            `${BASE_URL}/vocabulary/changeTranslation`,
            {
                id: userVocabularyId,
                translation: e.translation,
                vocabularyGroupId: wordDetails.vocabularyGroupId,
                partOfSpeech: mapPos(e.pos.toUpperCase()),
            },
            {
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "application/json",
                },
            },
        );
    }

    return (
        <div className="container mx-auto p-4">
            <Page pageHeader="Word Details"
                  goBackLink={`/words/${wordDetails.vocabularyGroupId}?learningLanguage=${wordDetails.wordTranslation?.wordFrom.language}&nativeLanguage=${wordDetails.wordTranslation?.wordTo.language}`}
                goBackText="To Vocabulary Groups">
                <div className="flex flex-wrap gap-3">
                    <Button onClick={regenerate} className="w-40">
                        Regenerate Examples
                    </Button>
                    <Button onClick={getPossibleTranslations} className="w-40">
                        Change Translation
                    </Button>
                </div>

                {possibleTranslations.map((e) => (
                    <Button
                        key={e.translation}
                        onClick={() => changeTranslation(e)}
                    >
                        {e.translation}
                    </Button>
                ))}

                <div className="flex justify-center w-full">
                    <WordForm
                        wordDetails={wordDetails}
                        loading={loading}
                        setLoading={setLoading}
                    />
                </div>
            </Page>

            {loading && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="bg-black bg-opacity-50 fixed inset-0 z-40"></div>
                    <Spin
                        size="default"
                        className="z-50"
                        indicator={<LoadingOutlined spin/>}
                    />
                </div>
            )}
        </div>
    );
};

export default WordDetails;

export const Field = ({k, value}: { k: string; value: any }) => {
    return (
        <p>
            <strong>{k}:</strong> {value || k}
        </p>
    );
};

export const WordForm = ({
                             wordDetails,
                             loading,
                             setLoading,
                         }: {
    wordDetails: UserVocabulary;
    loading: boolean;
    setLoading: (val: boolean) => void;
}) => {
    const [form] = Form.useForm();
    const [word, setWord] = useState<Map<string, any>>(new Map([]));
    const [tempWord, setTempWord] = useState<Map<string, any>>(new Map([]));
    const token = useSelector(selectToken);
    const isMobile = window.innerWidth < 768;

    useEffect(() => {
        setWord(
            new Map<string, any>(
                Object.entries({
                    word: wordDetails.wordTranslation?.wordFrom.word,
                    wordTranslation: wordDetails.wordTranslation?.wordTo.word,
                    partOfSpeech_:
                    wordDetails?.wordTranslation?.wordFrom.partOfSpeech,
                    gender_:
                        wordDetails.wordTranslation?.wordFrom?.wordDictionary
                            ?.conjugation?.conjugation?.gender || "",
                    example: wordDetails.wordExampleTranslation?.example,
                    exampleTranslation:
                    wordDetails.wordExampleTranslation?.exampleTranslation,
                    wordSound: wordDetails.wordTranslation?.wordFrom.soundUrl,
                    imageUrl: wordDetails.wordTranslation?.wordFrom.imageUrl,
                    exampleSound: wordDetails.wordExampleTranslation?.soundUrl,
                    description:
                    wordDetails.wordTranslation?.wordFrom.wordDictionary
                        .description,
                    descriptionTranslation:
                    wordDetails.wordTranslation?.wordFrom.wordDictionary
                        .descriptionTranslation,
                    synonyms:
                        wordDetails.wordTranslation?.wordFrom.wordDictionary.synonyms?.join(
                            ", ",
                        ),
                }),
            ),
        );
    }, [wordDetails.wordExampleTranslation?.example, wordDetails.wordExampleTranslation?.exampleTranslation, wordDetails.wordExampleTranslation?.soundUrl, wordDetails.wordTranslation?.wordFrom.imageUrl, wordDetails.wordTranslation?.wordFrom.partOfSpeech, wordDetails.wordTranslation?.wordFrom.soundUrl, wordDetails.wordTranslation?.wordFrom.word, wordDetails.wordTranslation?.wordFrom.wordDictionary?.conjugation?.conjugation?.gender, wordDetails.wordTranslation?.wordFrom.wordDictionary.description, wordDetails.wordTranslation?.wordFrom.wordDictionary.descriptionTranslation, wordDetails.wordTranslation?.wordFrom.wordDictionary.synonyms, wordDetails.wordTranslation?.wordTo.word]);

    async function update() {
        setLoading(true);
        const e = tempWord;
        if (e.get("synonyms")) {
            const synonyms = (e.get("synonyms") ?? "").split(",");
            e.set("synonyms", synonyms);
        }

        e.set("userVocabularyId", wordDetails.userVocabularyId);
        if (!e.get("partOfSpeech")) {
            e.set(
                "partOfSpeech",
                wordDetails.wordTranslation?.wordFrom.partOfSpeech,
            );
        }

        const object = Object.fromEntries(e.entries());

        const res = await axios.put(
            `${BASE_URL}/vocabulary/updateWord`,
            object,
            {
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "application/json",
                },
            },
        );
        setLoading(false);
        message.success("Word updated");
        console.log(res.data);
    }

    async function regenerateSound(key: string) {
        setLoading(true);
        const example = key !== "wordSound";

        axios.get(
            `${BASE_URL}/vocabulary/regenerateSounds/${wordDetails.userVocabularyId}?example=${example}`,
            {
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "application/json",
                },
            },
        );
        setLoading(false);
    }

    async function changeWordImage(e: any) {
        setLoading(true);
        const form = new FormData();
        form.append("file", e.target.files[0]);

        await axios.post(
            `${BASE_URL}/vocabulary/changeWordImage/${wordDetails.userVocabularyId}`,
            form,
            {
                headers: {
                    Authorization: "Bearer " + token,
                },
            },
        );

        setLoading(false);
        message.success("Image changed");
    }

    async function reloadWordImage() {
        setLoading(true);
        await axios.get(
            `${BASE_URL}/vocabulary/reloadWordImage/${wordDetails.userVocabularyId}`,
            {
                headers: {
                    Authorization: "Bearer " + token,
                },
            },
        );
        setLoading(false);
    }

    return (
        <Card className="w-full max-w-4xl">
            <Form
                form={form}
                name="word_form"
                layout="vertical"
                onFinish={update}
                className="flex flex-col gap-4"
            >
                <Row gutter={16}>
                    {Array.from(word.entries()).map(([key, value]) => {
                        if (key.toLowerCase().endsWith("_")) {
                            return (
                                <Col span={24} key={key} className='m-1' >
                                    <Form.Item
                                        label={toHeader(key.replace("_", ""))}
                                        tooltip={`Enter ${key.replace("_", "")}`}
                                    >
                                        <AutoComplete
                                            defaultValue={value}
                                            options={(() => {
                                                switch (key) {
                                                    case "partOfSpeech_":
                                                        return PartOfSpeechValues.map(
                                                            (a) => ({
                                                                key: a,
                                                                value: a,
                                                            }),
                                                        );
                                                    case "gender_":
                                                        return GenderValues.map(
                                                            (i) => ({
                                                                key: i,
                                                                value: i,
                                                            }),
                                                        );
                                                    default:
                                                        return [];
                                                }
                                            })()}
                                            onChange={(v) => {
                                                setTempWord(() => {
                                                    tempWord.set(
                                                        key.substring(
                                                            0,
                                                            key.length - 1,
                                                        ),
                                                        v,
                                                    );

                                                    return new Map<string, any>(
                                                        tempWord.entries(),
                                                    );
                                                });
                                            }}
                                        >
                                            <Input placeholder={value}/>
                                        </AutoComplete>
                                    </Form.Item>
                                </Col>
                            );
                        }
                        if (key.toLowerCase().includes("sound")) {
                            return (
                                <Col span={24} key={key} className='m-1'>
                                    <Form.Item label={toHeader(key)}>
                                        <audio
                                            src={value}
                                            controls
                                            className="word-audio"
                                        />
                                        <Button
                                            onClick={() =>
                                                regenerateSound(key)
                                            }
                                            className="mt-2"
                                        >
                                            Regenerate sound
                                        </Button>
                                    </Form.Item>
                                </Col>
                            );
                        } else if (key.toLowerCase().includes("image")) {
                            return (
                                <Col span={24} key={key} className='m-1'>
                                    <Form.Item label={toHeader(key)}>
                                        <img
                                            src={value}
                                            alt="img"
                                            width={isMobile ? 250 : 450}
                                            height={isMobile ? 250 : 450}
                                        />
                                        <Input
                                            type="file"
                                            accept="image/*"
                                            onChange={changeWordImage}
                                            className="hidden mt-4 w-[90%]"
                                        />
                                        <Button
                                            className="mt-3"
                                            onClick={reloadWordImage}
                                        >
                                            Reload image
                                        </Button>
                                    </Form.Item>
                                </Col>
                            );
                        }

                        return (
                            <Col span={24} key={key} className='m-2'>
                                <Form.Item label={toHeader(key)}>
                                    <Input
                                        placeholder={key}
                                        key={key}
                                        value={value}
                                        onChange={(e) => {
                                            const v = e.target.value;
                                            setTempWord(() => {
                                                tempWord.set(key, v);
                                                return new Map<string, any>(
                                                    tempWord.entries(),
                                                );
                                            });
                                        }}
                                    ></Input>
                                </Form.Item>
                            </Col>
                        );
                    })}
                </Row>
                <Button
                    type="primary"
                    htmlType="submit"
                    className="self-center"
                >
                    Update
                </Button>
            </Form>
        </Card>
    );
};
