import React, {ReactNode, useEffect, useRef} from 'react'

const Popup = ({ onClose, children, closeOnOutsideClick = true }: { onClose: () => void, children: ReactNode, closeOnOutsideClick?: boolean }) => {

    const ref = useRef(null);

    useEffect(() => {
        if (!closeOnOutsideClick) return;

        const handleClickOutside = (event: any) => {
            if (ref.current) {
                // @ts-ignore
                if (!ref.current.contains(event.target)) {
                    onClose();
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside, true);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true);
        };
    }, [closeOnOutsideClick, onClose]);

    useEffect(() => {
        const handleESCClick = (event: any) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        document.addEventListener('keydown', handleESCClick, true);

        return () => {
            document.removeEventListener('keydown', handleESCClick, true);
        };
    }, [onClose]);

    return (
        <div className="z-40 fixed inset-0 flex justify-center items-center">
            <div className="bg-black bg-opacity-50 fixed inset-0 z-40"></div>
            <div ref={ref} className="flex gap-2 flex-col relative z-50 max-w-md mx-4 sm:mx-8 p-4 sm:p-8 bg-white dark:bg-[#2b333b] shadow-md rounded-2xl">
                {children}
            </div>
        </div>

    )
}

export default Popup
