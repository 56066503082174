import {AutoComplete, Button, Form, Input, message, Select} from "antd";
import axios from "axios";
import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useParams, useSearchParams} from "react-router-dom";
import {v4 as uuid} from "uuid";
import Loading from "../components/Loading";
import Page from "../components/Page";
import Popup from "../components/Popup";
import {BASE_URL} from "../constant";
import {selectVocabularyGroup} from "../store/lists/listSelector";
import {Group, setVocabularyGroup} from '../store/lists/listSlice';
import {selectToken} from "../store/user/userSelector";
import {mapPos} from "../utils/globalUtils";
import {get, post} from "../utils/httpUtils";
import {PartOfSpeechValues} from "./WordDetails";
import EnhancedTable, {ColDef, HeadCell, ToolbarFunctions} from "../components/EnhancedTable";
import UpgradeIcon from '@mui/icons-material/Upgrade';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ShuffleOnIcon from '@mui/icons-material/ShuffleOn';
import Card from '@mui/material/Card';
import CardContent from "@mui/material/CardContent";

export type Word = {
    userVocabularyId: number;
    wordFrom: string;
    wordTo: string;
    vocabularyGroupId: number;
    vocabularyId: number;
    wordTranslationId: number;
    wordFromId: number;
    wordToId: number;
    partOfSpeech: string;
};

type TableWord = {
    id: number;
    word: string;
    translation: string;
    partOfSpeech: string;
}

const headCells: HeadCell<TableWord>[] = [
    {id: 'id', numeric: true, disablePadding: false, label: 'Id'},
    {id: 'word', numeric: false, disablePadding: false, label: 'Word'},
    {id: 'translation', numeric: false, disablePadding: false, label: 'Translation'},
    {id: 'partOfSpeech', numeric: false, disablePadding: false, label: 'Part of Speech'},
]


const Words = () => {
    const dispatch = useDispatch();
    const {groupId} = useParams();
    const [searchParams] = useSearchParams();
    const token = useSelector(selectToken);
    const vocabularyGroup = useSelector(selectVocabularyGroup);
    const learningLanguage = searchParams.get("learningLanguage") ?? vocabularyGroup.learningLanguage;
    const nativeLanguage = searchParams.get("nativeLanguage") ?? vocabularyGroup.nativeLanguage;
    const [initialWords, setInitialWords] = useState<Word[]>([]);
    const [words, setWords] = useState<Word[]>([]);
    const [translations, setTranslations] = useState<any[]>([]);
    const [translationsOpen, setTranslationsOpen] = useState<boolean>(false);
    const [translationOptionsOpen, setTranslationOptionsOpen] = useState<boolean>(false);
    const [translationOptions, setTranslationOptions] = useState<any[]>([]);
    const [word, setWord] = useState<string>();
    const [id, setId] = useState<number>();
    const [loading, setLoading] = useState<boolean>(false);
    const [messageApi,] = message.useMessage();
    const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
    const [vocabularyGroups, setVocabularyGroupIds] = useState<Group[]>([])
    const [globalUpdatePopupOpen, setGlobalUpdatePopupOpen] = useState<boolean>(false)
    const [moveWordsPopupOpen, setMoveWordsPopupOpen] = useState<boolean>(false)
    const [generateTrainingPopup, setGenerateTrainingPopup] = useState<boolean>(false)
    const [completeTrainingJson, setCompleteTrainingJson] = useState<object>({})

    const columns: ColDef<TableWord>[] = [
        {field: 'id', headerName: 'Id', width: 75},
        {
            field: 'word', headerName: 'Word', width: 200, renderCell: (row) => (
                <Link to={`/wordDetails/${row.id}`} className="underline text-blue-500">
                    {row.word}
                </Link>
            )
        },
        {field: 'translation', headerName: 'Translation', width: 200},
        {field: 'partOfSpeech', headerName: 'Part of Speech', width: 100},
        {
            field: 'action',
            headerName: 'Action',
            width: 150,
            renderCell: (row) => (
                <strong>
                    <button
                        className="underline hover:text-blue-500"
                        onClick={() => deleteWord(row.id).then(r => r)}
                    >
                        Delete
                    </button>
                </strong>
            )
        },
    ]

    const error = async (errorMessage: string) => {
        await messageApi.error({
            type: "error",
            content: errorMessage,
            style: {
                width: "8rem",
                height: "7rem"
            }
        })
    }

    const fetch = useCallback(async () => {
        setLoading(true);
        const data = await get(
            `/vocabulary/getWords?vocabularyGroupId=${groupId}`
        );

        setWords(data.words.reverse());
        setInitialWords(data.words.reverse());
        dispatch(setVocabularyGroup(data.vocabularyGroup))
        setLoading(false);
    }, [dispatch, groupId])

    useEffect(() => {
        if (!token || !groupId) return;

        fetch().then();
    }, [fetch, groupId, token]);

    function distinctTranslations(arr: any[]) {
        const visited: any[] = [];

        for (let e of arr) {
            const find = visited.find((v) => v.translation === e.translation);
            if (!find) {
                visited.push(e);
            }
        }

        return visited;
    }

    async function getTranslations(value: string, popup: boolean, id?: number) {
        const data = {
            word: value,
            targetLanguage: nativeLanguage,
            sourceLanguage: learningLanguage,
            wordLimit: 100,
        };

        const res = await post(
            `/vocabulary/getPossibleTranslations`,
            data
        );
        if (!res) return;

        const result = distinctTranslations(res.possibleTranslations);
        console.log("🚀 ~ getPossTranslations ~ result:", result);
        if (popup) {
            setTranslations(result);
            setTranslationsOpen(true);
        } else {
            setTranslationOptions(
                result.map((e) => ({
                    value: e.translation + "\t" + e.pos,
                    key: e.translation,
                })),
            );
            setTranslationOptionsOpen(true)
        }
        setWord(value);
        if (id) {
            setId(id);
        }
    }

    async function deleteWord(id: number) {
        const url = `${BASE_URL}/vocabulary/delete-word-admin?userVocabularyId=${id}`;
        const res = await axios.delete(url, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });

        if (res.status / 100 === 4) {
            return;
        }

        const updatedWords = words.filter(
            (word) => word?.userVocabularyId !== id,
        );
        setWords(updatedWords);
    }

    const dataSource: TableWord[] = words.map((word) => ({
        id: word.userVocabularyId,
        word: word.wordFrom,
        translation: word.wordTo,
        partOfSpeech: word.partOfSpeech,
    }));

    async function onSelect(value: string) {
        console.log("🚀 ~ value:", value);
        setLoading(true)

        if (!word) {
            await error("Word is missing");
            return;
        } else if (!groupId) {
            await error("Group id is missing")
            return;
        } else if (!vocabularyGroup.vocabularyId) {
            await error("Vocabulary id is missing");
            return;
        }

        const translation = value.split("\t")[0];
        const pos = value.split("\t")[1];

        const tempId = uuid();

        const addWordData = {
            tempId,
            word,
            vocabularyId: vocabularyGroup.vocabularyId,
            vocabularyGroupId: +groupId,
            wordTranslation: {translation, pos},
        };

        await post(`/vocabulary/add-word`, addWordData);
        setTranslationOptions([])
        setLoading(false)
    }

    async function changeTranslation(translation: any) {
        setLoading(true);
        console.log("🚀 ~ changeTranslation ~ translation:", translation);
        if (!groupId) {
            setLoading(false)
            await error("Group id is missing");
            return;
        }


        await post(
            "/vocabulary/changeTranslation",
            {
                id,
                translation: translation.translation,
                vocabularyGroupId: +groupId,
                partOfSpeech: mapPos(translation.pos.toUpperCase()),
            }
        );
        setLoading(false);
    }

    function filter(value: string) {
        if (!value || value.length === 0) {
            setWords(initialWords);
            return;
        }

        const filtered = words.filter((a) => a.wordTo.includes(value) || a.wordFrom.includes(value));

        setWords(filtered);
    }

    async function regenerateImagesForAllWords() {
        setLoading(true);
        const path = `/vocabulary/regenerateImagesForAllWords/${groupId}`;
        await get(path)

        setLoading(false);
    }

    async function generateTraining(subpath: string) {
        const path = `/learning/${subpath}`;
        const res = await post(path, {
            vocabularyGroupId: vocabularyGroup.groupId,
            vocabularyId: vocabularyGroup.vocabularyId,
            userVocabularyIds: selectedRowKeys
        })

        if (!res) {
            message.error("Error while generating training results")
        }

        message.success("Training results generated successfully")
        setCompleteTrainingJson(res)
        setGenerateTrainingPopup(true)
    }

    async function completeTraining() {
        const completePath = `/learning/complete`
        const response = await post(completePath, completeTrainingJson)
        if (!response) {
            message.error("Error while completing training")
        } else {
            message.success("Training completed successfully")
        }
        setGenerateTrainingPopup(false)
    }

    async function updatePos(userVocabularyId: number, newPos: string) {
        setLoading(true);
        const e = new Map<string, any>();

        e.set("userVocabularyId", userVocabularyId);
        if (!e.get("partOfSpeech")) {
            e.set(
                "partOfSpeech",
                newPos
            );
        }

        const object = Object.fromEntries(e.entries());

        const res = await axios.put(
            `${BASE_URL}/vocabulary/updateWord`,
            object,
            {
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "application/json",
                },
            },
        );
        setLoading(false);
        message.success("Word updated");
        console.log(res.data);
    }


    async function updateRecords(toUpdate: FormDataEvent) {
        setGlobalUpdatePopupOpen(false)
        // @ts-ignore
        if (!toUpdate.partOfSpeech) {
            message.error("Part of speech is missing")
        }
        setLoading(true);
        for (const id of selectedRowKeys) {
            // @ts-ignore
            await updatePos(id, toUpdate.partOfSpeech);
        }
        await fetch()
        setLoading(false)
    }

    async function fetchVocabularyGroups() {
        setLoading(true)
        const url = `/vocabularyGroup/getVocabularyGroups?learningLanguage=${learningLanguage}&nativeLanguage=${nativeLanguage}`;

        const response: Group[] = await get(url);
        setVocabularyGroupIds(response.filter(e => e.groupId !== vocabularyGroup.groupId));
        setLoading(false)
    }

    async function moveWords(e: FormDataEvent) {
        setLoading(true)
        setMoveWordsPopupOpen(false)

        const data = {
            vocabularyGroupIdFrom: vocabularyGroup.groupId,
            // @ts-ignore
            vocabularyGroupIdTo: e.vocabularyGroupId,
            userVocabularyIds: selectedRowKeys,
        }

        const res = await post(`/vocabulary/moveWords`, data)
        console.log(res);
        setLoading(false)
    }

    const toolbarFunctions: ToolbarFunctions = [
        {
            icon: <ShuffleOnIcon/>,
            title: "generateRandomTrainingResults",
            onClick: () => generateTraining("generateRandomTrainingResults")
        },
        {
            icon: <ThumbUpAltIcon/>,
            title: "generateAllCorrectTrainingResults",
            onClick: () => generateTraining("generateAllCorrectTrainingResults")
        },
        {
            icon: <ThumbDownAltIcon/>,
            title: "generateAllWrongTrainingResults",
            onClick: () => generateTraining("generateAllWrongTrainingResults")
        },
        {
            icon: <TrendingFlatIcon/>,
            title: "moveWords",
            onClick: async () => {
                await fetchVocabularyGroups()
                setMoveWordsPopupOpen(true)
            }
        },
        {
            icon: <UpgradeIcon/>,
            title: "updateAllRecords",
            onClick: () => setGlobalUpdatePopupOpen(true)
        }
    ]

    console.log("selectedRowKeys", selectedRowKeys)

    return (
        <Page pageHeader={"Word Management"} learningLanguage={learningLanguage} nativeLanguage={nativeLanguage}>
            <div className="w-[300px]">
                <Input.Search
                    size="large"
                    placeholder="Add New Word"
                    enterButton
                    onSearch={(value) => getTranslations(value, false)}
                />

                <ul className="w-[300px] mt-3 bg-white border-gray-200 rounded-md shadow-md z-50 fixed">
                    {translationOptionsOpen && translationOptions?.map((option, index) => (
                        <li key={option} className="border-b border-gray-100 last:border-0">
                            <button
                                className="w-full text-left px-4 py-2 hover:bg-gray-100 focus:outline-none"
                                onClick={() => onSelect(option.value)}
                            >
                                {option.value}
                            </button>
                        </li>
                    ))}
                </ul>
            </div>

            <Input.Search
                style={{width: 300}}
                size="large"
                placeholder="Search"
                enterButton
                onChange={(e) => filter(e.target.value)}
            />

            <Button
                type="primary"
                className="my-7"
                onClick={regenerateImagesForAllWords}
            >
                Regenerate Images for all words
            </Button>

            <span>Number of words: {words.length}</span>

            <EnhancedTable rows={dataSource}
                           columns={columns}
                           headCells={headCells}
                           toolbarFunctions={toolbarFunctions}
                           handleRowClick={(e, id) => {
                               if (selectedRowKeys.includes(id)) {
                                   setSelectedRowKeys(selectedRowKeys.filter(e => e !== id))
                               } else {
                                   setSelectedRowKeys([...selectedRowKeys, id])
                               }
                           }}
                           handleSelectAll={() => setSelectedRowKeys(dataSource.map(e => e.id))}
                           handleDeselectAll={() => setSelectedRowKeys([])}
            />

            {translationsOpen && (
                <Popup onClose={() => setTranslationsOpen(false)}>
                    {translations?.map((e) => (
                        <button
                            key={e.translation}
                            onClick={() => changeTranslation(e)}
                            className="btn w-full flex gap-4"
                        >
                            <span>{e.translation}</span>
                            <span>{e.pos}</span>
                        </button>
                    ))}
                </Popup>
            )}
            {globalUpdatePopupOpen && (
                <Popup closeOnOutsideClick={false} onClose={() => setGlobalUpdatePopupOpen(false)}>
                    <Form onFinish={updateRecords}>
                        <Form.Item label="Part of speech" name="partOfSpeech">
                            <AutoComplete value="NOUN" options={PartOfSpeechValues.map(
                                (a) => ({
                                    key: a,
                                    value: a,
                                }),
                            )}>
                                <Input/>
                            </AutoComplete>
                        </Form.Item>
                        <Form.Item>
                            <Button htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Popup>
            )}
            {moveWordsPopupOpen && vocabularyGroups && (
                <Popup closeOnOutsideClick={false} onClose={() => setMoveWordsPopupOpen(false)}>
                    <Form onFinish={moveWords}>
                        <Form.Item label="Vocabulary Group" name="vocabularyGroupId">
                            <Select className="w-[150px]" options={vocabularyGroups.map(e => ({
                                key: e.groupId,
                                value: e.groupId,
                                label: e.name
                            }))} defaultValue={{
                                key: vocabularyGroups[0].groupId,
                                value: vocabularyGroups[0].groupId,
                                label: vocabularyGroups[0].name
                            }}>
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Button htmlType="submit" type="primary">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Popup>
            )}
            {generateTrainingPopup && (
                <Popup onClose={() => setGenerateTrainingPopup(false)}>
                    <Form onFinish={completeTraining} className="flex flex-col flex-wrap gap-3">
                        <Card variant="outlined">
                            <CardContent>
                                {completeTrainingJson && JSON.stringify(completeTrainingJson)}
                            </CardContent>
                        </Card>
                        <Form.Item>
                            <Button htmlType="submit" type="primary">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Popup>
            )}
            {loading && <Loading/>}
        </Page>
    )
};

export default Words;
