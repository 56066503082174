import React, {ChangeEvent, JSX, MouseEventHandler, useMemo, useState} from "react";
import {
    Box,
    Checkbox,
    FormControlLabel,
    IconButton,
    Paper,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Toolbar,
    Tooltip,
    Typography
} from "@mui/material";
import {visuallyHidden} from '@mui/utils';
import FilterListIcon from '@mui/icons-material/FilterList';

export type ToolbarFunctions = (ToolbarStringFunction | ToolbarIconFunction)[];

export type ColDef<T> = {
    field: string,
    headerName: string,
    width: number;
    renderCell?: (row: T) => JSX.Element
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}


type Order = 'asc' | 'desc';

interface EnhancedTableHeaderProps<T extends EnhancedTableMinimal> {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
    onSelectAllClick: (event: ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
    headCells: HeadCell<T>[];
}

interface EnhancedTableProps<T extends EnhancedTableMinimal> {
    rows: T[];
    columns: ColDef<T>[];
    readonly headCells: HeadCell<T>[];
    toolbarFunctions?: (ToolbarStringFunction | ToolbarIconFunction)[];
    handleSelectAll?: (event: ChangeEvent<HTMLInputElement>) => void;
    handleDeselectAll?: (event: ChangeEvent<HTMLInputElement>) => void;
    handleRowClick?: (event: React.MouseEvent<unknown>, id: number) => void;
}

export interface HeadCell<T extends EnhancedTableMinimal> {
    disablePadding: boolean;
    id: keyof T;
    label: string;
    numeric: boolean;
}


function EnhancedTableHead<T extends EnhancedTableMinimal>({
                                                               onSelectAllClick,
                                                               order,
                                                               orderBy,
                                                               numSelected,
                                                               rowCount,
                                                               onRequestSort,
                                                               headCells
                                                           }: EnhancedTableHeaderProps<T>) {
    const createSortHandler =
        (property: keyof T) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={String(headCell.id)}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

interface ToolbarStringFunction {
    title: string;
    onClick: MouseEventHandler<HTMLButtonElement>;
}

interface ToolbarIconFunction {
    title: string;
    icon: JSX.Element;
    onClick: MouseEventHandler<HTMLButtonElement>;
}

interface EnhancedTableToolbarProps {
    numSelected: number;
    functions?: (ToolbarStringFunction | ToolbarIconFunction)[];
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
    const {numSelected, functions} = props;

    return (
        <Toolbar
            sx={[
                {
                    pl: {sm: 2},
                    pr: {xs: 1, sm: 1},
                },
            ]}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{flex: '1 1 100%'}}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{flex: '1 1 100%'}}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Vocabulary Groups
                </Typography>
            )}
            {numSelected > 0 && functions ? (
                functions.map((func, index) => {
                    if ('icon' in func) {
                        return (
                            <Tooltip key={index} title={func.title}>
                                <IconButton onClick={func.onClick}>
                                    {func.icon}
                                </IconButton>
                            </Tooltip>
                        )
                    } else {
                        return (
                            <Tooltip key={index} title={func.title}>
                                <IconButton onClick={func.onClick}>
                                    {func.title}
                                </IconButton>
                            </Tooltip>
                        )
                    }
                })
            ) : (
                <Tooltip title="Filter list">
                    <IconButton>
                        <FilterListIcon/>
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
}

interface EnhancedTableMinimal {
    id: number;
}

export default function EnhancedTable<T extends EnhancedTableMinimal>({
                                                                          rows,
                                                                          columns,
                                                                          headCells,
                                                                          toolbarFunctions,
                                                                          handleDeselectAll,
                                                                          handleSelectAll,
                                                                          handleRowClick
                                                                      }: EnhancedTableProps<T>) {
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof T>('id');
    const [selected, setSelected] = useState<readonly number[]>([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof T,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleAllSelect = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            handleSelectAll && handleSelectAll(event)
            return;
        }
        handleDeselectAll && handleDeselectAll(event)
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: readonly number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
        handleRowClick && handleRowClick(event, id)
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDense(event.target.checked);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = useMemo(
        () =>
            [...rows]
                // @ts-ignore
                .sort(getComparator(order, String(orderBy)))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
        [order, orderBy, page, rows, rowsPerPage],
    );

    return (
        <Box sx={{width: '100%'}}>
            <Paper sx={{width: '100%', mb: 2}}>
                <EnhancedTableToolbar functions={toolbarFunctions} numSelected={selected.length}/>
                <TableContainer>
                    <Table
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={String(orderBy)}
                            onSelectAllClick={handleAllSelect}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            headCells={headCells}/>
                        <TableBody>
                            {visibleRows.map((row, index) => {
                                const isItemSelected = selected.includes(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) => handleClick(event, row.id)}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.id}
                                        selected={isItemSelected}
                                        sx={{cursor: 'pointer'}}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.field}
                                                align="left"
                                            >
                                                {/* @ts-ignore */}

                                                {column.renderCell ? column.renderCell(row) : row[column.field]}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6}/>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50 ,100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense}/>}
                label="Dense padding"
            />
        </Box>
    );
}
