import {CloseOutlined} from "@ant-design/icons";
import {AutoComplete, Button, Drawer, Form, Input, message, Space,} from "antd";
import {Header} from "antd/es/layout/layout";
import axios from "axios";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useSearchParams} from "react-router-dom";
import {ROUTES} from "../app/routes";
import Loading from "../components/Loading";
import {BASE_URL} from "../constant";
import {selectLists, selectVocabularyGroup,} from "../store/lists/listSelector";
import {
    deleteGroupRedux,
    List,
    setLearningLanguageRedux,
    setLists,
    setNativeLanguageRedux,
    setVocabularyGroup,
} from "../store/lists/listSlice";
import {selectToken} from "../store/user/userSelector";
import {extractHeader, toPath} from "../utils/globalUtils";
import {get, post} from "../utils/httpUtils";
import {ReactComponent as BurgerImage} from '../assets/burger.svg'
import EnhancedTable, {ColDef, HeadCell} from "../components/EnhancedTable";


export const Languages = ["GERMAN", "ENGLISH", "SPANISH"];

export type SuggestedListsTableData = {
    id: number,
    name: string,
}

const headCells: HeadCell<SuggestedListsTableData>[] = [
    {
        id: 'id',
        numeric: false,
        label: 'Id',
        disablePadding: true
    },
    {
        id: 'name',
        numeric: false,
        label: "Name",
        disablePadding: true
    }
]

const SuggestedLists = () => {
        const dispatch = useDispatch();
        const token = useSelector(selectToken);
        const [searchParams] = useSearchParams();
        const vocabularyGroup = useSelector(selectVocabularyGroup);
        const learningLanguageParam =
            searchParams.get("learningLanguage") ??
            vocabularyGroup.learningLanguage ??
            "";
        const nativeLanguageParam =
            searchParams.get("nativeLanguage") ??
            vocabularyGroup.nativeLanguage ??
            "";
        const lists: List[] = useSelector(selectLists);
        const [drawer, setDrawer] = useState<boolean>(false);
        const [learningLanguage, setLearningLanguage] = useState<string>(
            learningLanguageParam,
        );
        const [nativeLanguage, setNativeLanguage] =
            useState<string>(nativeLanguageParam);
        const [opened, setOpened] = useState<boolean>(false);
        const [messageApi,] = message.useMessage();
        const [loading, setLoading] = useState<boolean>(false);
        const [dataSource, setDataSource] = useState<SuggestedListsTableData[]>([]);
        const [initialDataSource, setInitialDataSource] = useState<SuggestedListsTableData[]>([]);
        const [manualGroupCreationPopupOpen, setManualGroupCreationPopupOpen] = useState<boolean>(false);

        const columns: ColDef<SuggestedListsTableData>[] = [
            {field: 'id', headerName: 'Id', width: 100},
            {
                field: 'name',
                headerName: 'Name',
                width: 200,
                renderCell: (row) => (
                    <Link onClick={() => {
                        dispatch(
                            setLearningLanguageRedux(
                                learningLanguage,
                            ),
                        );
                        dispatch(
                            setNativeLanguageRedux(nativeLanguage),
                        );
                        dispatch(
                            setVocabularyGroup({
                                ...row,
                                learningLanguage,
                                nativeLanguage,
                            }),
                        );
                    }}
                          to={`/words/${row.id}?learningLanguage=${learningLanguage}&nativeLanguage=${nativeLanguage}`}
                          className="underline text-blue-500">
                        {row.name}
                    </Link>
                )
            },
            {
                field: 'action',
                headerName: 'Action',
                width: 150,
                renderCell: (row) => (
                    <strong>
                        <button
                            className="underline hover:text-blue-500"
                            onClick={() => deleteVocabularyGroup(row.id).then(r => r)}
                        >
                            Delete
                        </button>
                    </strong>
                ),
            },
        ]

        const onSearch = (e: any) => {
            e.preventDefault();
            const val = e.target.value;
            if (!val || val.length === 0) {
                setDataSource(
                    lists[0]?.groups?.map((e) => ({
                        id: e.groupId,
                        name: e.name
                    }))
                );
                return;
            }

            setDataSource(initialDataSource.filter((e) => e.name.toLowerCase().trim().includes(val)
                || e.id.toString().includes(val)));
        };

        const error = (errorMessage: string) => {
            messageApi.error({
                type: "error",
                content: errorMessage,
                style: {
                    width: "8rem",
                    height: "7rem",
                },
            });
        };

        const fetch = async () => {
            setLoading(true);
            const url = `/vocabularyGroup/getVocabularyGroups?learningLanguage=${learningLanguage}&nativeLanguage=${nativeLanguage}`;

            if (
                !token ||
                learningLanguage === "" ||
                nativeLanguage === "" ||
                nativeLanguage === learningLanguage
            ) {
                setLoading(false);
                error("Incorrect language selection");
                return;
            }
            const res = await get(url);

            console.log(res);
            dispatch(
                setLists([
                    {groups: [...res], learningLanguage, nativeLanguage},
                ]),
            );
            setLoading(false);
        };

        const onClick = (key: string) => {
            if (key.includes("NATIVE")) {
                setNativeLanguage(key.replaceAll("NATIVE", ""));
            } else {
                setLearningLanguage(key);
            }
        };

        const deleteVocabularyGroup = async (groupId: number) => {
            setLoading(true)
            const url = `${BASE_URL}/vocabularyGroup/deletePredefined/${groupId}`;
            const res = await axios.delete(url, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
            });

            if (Math.floor(res.status / 100) === 2) {
                message.info("successful delete");
                dispatch(deleteGroupRedux(groupId));
            }
            await fetch()
            setLoading(false)
        };

        useEffect(() => {
            setInitialDataSource(
                lists[0]?.groups?.map(e => ({
                        id: e.groupId,
                        name: e.name
                    }
                )));

            setDataSource(
                lists[0]?.groups?.map(e => ({
                        id: e.groupId,
                        name: e.name
                    })
                ));

        }, [lists]);

        async function addNewVocabularyGroup(e: FormDataEvent) {
            setLoading(true)
            setOpened(false)
            // @ts-ignore
            const name = e.vocabularyGroupName;
            // @ts-ignore
            const file = e.file;
            const data = {
                filename: file,
                vocabularyGroupName: name,
                sourceLanguage: learningLanguage,
                targetLanguage: nativeLanguage,
            };

            const url = `/scripts/wordsAdding`;
            await post(url, data);

            setLoading(false)
        }

        const items = Languages.map((lang) => ({value: lang, key: lang}));
// @ts-ignore
        const secondItems = items
            .filter((e) => e?.value !== learningLanguage)
            .map((e) => ({value: e.value, key: e.key + "NATIVE"}));

        useEffect(() => {
            if (learningLanguage && nativeLanguage) {
                fetch().then();
            }
        }, [learningLanguage, nativeLanguage]);

        const getStringFromJSX = (
            elem: React.ReactElement | string,
        ): string => {
            if (!elem) {
                return "";
            } else if (typeof elem === "string") {
                return elem;
            } else {
                // @ts-ignore
                const children = elem.props.children;
                if (children instanceof Array) {
                    return children.map((e) => getStringFromJSX(e)).join("");
                }
                return getStringFromJSX(children);
            }
        };

        const createNewPredefinedVocabularyGroup = async (e: FormDataEvent) => {
            setLoading(true)
            // @ts-ignore
            const name = e.vocabularyGroupName
            await post("/vocabularyGroup/createPredefinedFromLanguages", {
                learningLanguage,
                nativeLanguage,
                name,
            });
            await fetch()
            setLoading(false)
        }

        return (
            <>
                <Header className="h-auto p-5">

                    {
                        window.innerWidth < 768 ? (
                            <div className="flex justify-start items-center gap-5">
                                <Button onClick={() => setDrawer(true)}>
                                    <BurgerImage/>
                                </Button>
                                <Drawer
                                    title="Drawer with extra actions"
                                    placement="left"
                                    width={500}
                                    onClose={() => setDrawer(false)}
                                    open={drawer}
                                    extra={
                                        <Space>
                                            <Button onClick={() => setDrawer(false)}>Close</Button>
                                            <Button type="primary" onClick={() => setDrawer(false)}>
                                                OK
                                            </Button>
                                        </Space>
                                    }
                                >
                                    <ul className="flex flex-col gap-3">
                                        {
                                            ROUTES.filter(e => !e.path.includes(":") && e.path.toUpperCase() !== "LOGIN" && e.path !== "/").map(r => (
                                                <li key={r.path} className="flex p-2 bg-slate-100 rounded-md">
                                                    <Link to={toPath(r.path)}
                                                          className="text-blue-500">{extractHeader(r.path)}</Link>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </Drawer>
                            </div>
                        ) : (
                            <div className="flex justify-start flex-start gap-5">
                                {
                                    ROUTES.filter(e => !e.path.includes(":") && e.path.toUpperCase() !== "LOGIN").map(r => (
                                        <Link to={toPath(r.path)} key={r.path}
                                              className="text-blue-500">{extractHeader(r.path)}</Link>
                                    ))
                                }
                            </div>
                        )
                    }
                </Header>

                <div className="flex justify-center items-center flex-col gap-10">
                    <div className="flex flex-col gap-5">
                        <div className="flex justify-center items-start gap-2 mt-10 max-md:flex-col">
                            <AutoComplete
                                style={{width: 200}}
                                defaultValue={learningLanguage}
                                options={items}
                                onChange={(val, option) => onClick(val)}
                            />
                            <AutoComplete
                                defaultValue={nativeLanguage}
                                style={{width: 200}}
                                options={secondItems}
                                onChange={(val, option) => onClick(val + "NATIVE")}
                            />
                        </div>
                        {dataSource && (
                            <Input
                                placeholder="Search"
                                type="search"
                                onChange={onSearch}
                            />
                        )}
                    </div>
                    <button className="btn btn-sm" onClick={fetch}>
                        Get Predefined
                    </button>
                    <Space direction="vertical">
                        {/* BUTTONS */}
                        <div>
                            {learningLanguage && nativeLanguage && lists[0]?.groups && (
                                <div className="flex flex-wrap max-md:justify-center max-md:items-center gap-3">
                                    <Button onClick={() => setOpened(!opened)}>
                                        Add Row
                                    </Button>
                                    <Button onClick={() => setManualGroupCreationPopupOpen(true)}>
                                        Add new predefined vocabulary group
                                    </Button>
                                </div>
                            )}
                        </div>

                        {/* TABLE */}
                        {/*<Paper sx={{height: 400, width: '100%'}}>*/}
                        {/*    <DataGrid*/}
                        {/*        rows={dataSource}*/}
                        {/*        columns={columns}*/}
                        {/*        initialState={{pagination: {paginationModel}}}*/}
                        {/*        pageSizeOptions={[5, 10]}*/}
                        {/*        checkboxSelection*/}
                        {/*        sx={{border: 0}}*/}
                        {/*    />*/}
                        {/*</Paper>*/}
                        {dataSource && (
                            <EnhancedTable
                                rows={dataSource}
                                columns={columns}
                                headCells={headCells}
                            />
                        )}
                    </Space>
                </div>

                {opened && (
                    <div className="fixed inset-0 flex items-center justify-center z-50">
                        <div className="bg-black bg-opacity-50 fixed inset-0 z-40 "></div>
                        <div
                            className="relative z-50 max-w-md mx-4 sm:mx-8 p-4 sm:p-8 bg-white dark:bg-[#2b333b] shadow-md rounded-2xl">
                            <button
                                className={`cursor-pointer bg-opacity-0 hover:bg-opacity-50 transition-all duration-300 ease-out p-1 rounded-full`}
                                onClick={() => setOpened(false)}
                            >
                                <CloseOutlined/>
                            </button>
                            <Form onFinish={addNewVocabularyGroup}>
                                <Form.Item label="Name" name="vocabularyGroupName">
                                    <Input
                                        type="text"
                                        className="w-full p-2 border border-gray-300 rounded-md"
                                    />
                                </Form.Item>
                                <Form.Item label="File Path" name="file">
                                    <Input/>
                                </Form.Item>
                                <Form.Item>
                                    <Button htmlType="submit" type="primary">
                                        Add
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                )}
                {manualGroupCreationPopupOpen && (
                    <div className="fixed inset-0 flex items-center justify-center z-50">
                        <div className="bg-black bg-opacity-50 fixed inset-0 z-40 "></div>
                        <div
                            className="relative z-50 max-w-md mx-4 sm:mx-8 p-4 sm:p-8 bg-white dark:bg-[#2b333b] shadow-md rounded-2xl">
                            <button
                                className={`cursor-pointer bg-opacity-0 hover:bg-opacity-50 transition-all duration-300 ease-out p-1 rounded-full`}
                                onClick={() => setManualGroupCreationPopupOpen(false)}
                            >
                                <CloseOutlined/>
                            </button>
                            <Form onFinish={createNewPredefinedVocabularyGroup}>
                                <Form.Item label="Name" name="vocabularyGroupName">
                                    <Input
                                        type="text"
                                        className="w-full p-2 border border-gray-300 rounded-md"
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Button htmlType="submit" type="primary">
                                        Add
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                )}
                {loading && (

                    <Loading/>
                )}
            </>
        );
    }
;

export default SuggestedLists;
