import {createSlice} from "@reduxjs/toolkit";

export type Command = {
    command: string;
    id: number;
}

export type CommandState = {
    readonly commandHistory: Command[];
}

export const COMMAND_INITIAL_STATE: CommandState = {
    commandHistory: [],
}

export const commandSlice = createSlice({
    name: 'command',
    initialState: COMMAND_INITIAL_STATE,
    reducers: {
        addCommandHistory(state, action) {
            state.commandHistory.push(action.payload);
        },
        clearCommandHistory(state) {
            state.commandHistory = [];
        },
        deleteFromCommandHistory(state, action) {
            state.commandHistory = state.commandHistory.filter(item => item.id !== action.payload);
        }
    }
});

export const  commandReducer = commandSlice.reducer;
export const { clearCommandHistory, addCommandHistory, deleteFromCommandHistory } = commandSlice.actions;
