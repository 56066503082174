import {Table} from "antd";
import {useEffect, useState} from "react";
import axios from "axios";
import {BASE_URL} from "../constant";
import Column from "antd/es/table/Column";
import {useSelector} from "react-redux";
import {selectToken} from "../store/user/userSelector";

type AllWord = {
    word: string;
    translation: string;
    partOfSpeech: string;
}

function AllWords() {

    const [words, setWords] = useState<AllWord[]>([])
    const token = useSelector(selectToken);

    useEffect(() => {
        const fetch = async () => {
            const res = await axios.get(`${BASE_URL}/admin/allWords`, {
                headers: {
                    "Authorization": "Bearer " + token
                }
            })

            setWords(res.data)
        }

        fetch().then();
    }, [token]);


    return (
        <div className="flex justify-center">
            <div className="flex justify-center items-center flex-col mt-10 w-full max-w-4xl">
                <Table
                    dataSource={words}
                    className="w-full max-w-4xl"
                    pagination={{defaultPageSize: 20}}
                >
                    <Column title="Word" dataIndex="wordFrom" key="wordFrom"/>
                    <Column
                        title="Translation"
                        dataIndex="wordTo"
                        key="wordTo"
                    />
                    <Column
                        title="Part Of Speech"
                        dataIndex="partOfSpeech"
                        key="partOfSpeech"
                    />
                    {/*<Column*/}
                    {/*    title="Action"*/}
                    {/*    key="action"*/}
                    {/*    render={(_: any, record: any) => (*/}
                    {/*        <Space*/}
                    {/*            size="small"*/}
                    {/*            className="flex max-sm:flex-col max-sm:gap-2"*/}
                    {/*        >*/}
                    {/*            <button*/}
                    {/*                className="underline hover:text-blue-500"*/}
                    {/*                onClick={() =>*/}
                    {/*                    getTranslations(*/}
                    {/*                        record.wordFromValue,*/}
                    {/*                        true,*/}
                    {/*                        record.id,*/}
                    {/*                    )*/}
                    {/*                }*/}
                    {/*            >*/}
                    {/*                Change Translation*/}
                    {/*            </button>*/}
                    {/*            <button*/}
                    {/*                className="underline text-red-500 hover:text-blue-500"*/}
                    {/*                onClick={() => {*/}
                    {/*                    deleteWord(record.id);*/}
                    {/*                }}*/}
                    {/*            >*/}
                    {/*                Delete*/}
                    {/*            </button>*/}
                    {/*        </Space>*/}
                    {/*    )}*/}
                    {/*/>*/}
                </Table>
            </div>
        </div>
    )
}

export default AllWords;
