import axios from "axios";
import {BASE_URL} from "../constant";


export async function get(path: string) {
    const res = await axios.get(`${BASE_URL}${path}`, {
        headers: getHeaders()
    });

    return res.data;
}

export async function post(path: string, data: {} | []) {
    const res = await axios.post(`${BASE_URL}${path}`, data, {
        headers: getHeaders()
    })

    return res.data;
}


export function getHeaders() {
    return {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("authToken")
    }
}
