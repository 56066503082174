import {BASE_URL} from "../constant";
import {useDispatch, useSelector} from "react-redux";
import {selectToken} from "../store/user/userSelector";
import axios from "axios";
import {useRef, useState} from "react";
import {Button, Form, Input} from "antd";
import {selectCommandHistory} from "../store/user/commandSelector";
import {addCommandHistory, clearCommandHistory, Command, deleteFromCommandHistory} from "../store/user/commandSlice";
import Loading from "../components/Loading";
import Page from "../components/Page";
import {unescape} from "../utils/globalUtils";

function CommandPage() {
    const [resData, setResData] = useState("");
    const token = useSelector(selectToken);
    const history = useSelector(selectCommandHistory)
    const dispatch = useDispatch();
    const [showHistory, setShowHistory] = useState(false);
    const inputRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [input, setInput] = useState("");

    async function submitForm(value: string) {
        setLoading(true);
        const id = Math.random() * 1000 - 100
        const res = await axios.post(`${BASE_URL}/admin/execute`, {command: value}, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
        setResData(unescape(res.data.data));
        dispatch(addCommandHistory({command: value, id}))
        setLoading(false);
    }

    function clearHistory() {
        dispatch(clearCommandHistory())
    }

    console.log(resData);

    async function getFromHistory(item: Command) {
        setInput(item.command)
        await submitForm(item.command)
    }

    function deleteFromHistory(id: number) {
        dispatch(deleteFromCommandHistory(id))
    }

    return (
        <Page pageHeader="Command Page">
            <div className="flex justify-center items-start flex-wrap gap-4">
                <div className="flex justify-center items-center flex-col">
                    {/* @ts-ignore */}
                    <Form onSubmitCapture={(e) => submitForm(e.target[0].value)}
                          className="flex flex-col gap-5 w-[50rem] max-md:w-[20rem] max-sm:w-[15rem]">
                        <Form.Item>
                            <div className="gap-4 flex flex-col flex-wrap">
                                <Input ref={inputRef} value={input} onChange={(e) => setInput(e.target.value)} name="command" id="command"
                                       placeholder="Enter your command here"/>
                                <Button htmlType="submit" type="primary">Submit</Button>
                            </div>
                        </Form.Item>
                    </Form>
                    <pre>{resData}</pre>
                </div>
                <div className="flex flex-col gap-4">
                    <Button onClick={clearHistory}>Clear History</Button>
                    <Button onClick={() => setShowHistory(true)}>
                        Show History
                    </Button>
                    <ul>
                        {showHistory && history?.reverse().map((item, index) => (
                            <li key={item.id} className="flex justify-between">
                                <Button className="border-0 p-0"
                                        onClick={() => getFromHistory(item)}>{item.command}</Button>
                                <Button onClick={() => deleteFromHistory(item.id)}>X</Button>
                            </li>
                        ))
                        }
                    </ul>
                </div>
            </div>
            {loading && <Loading/>}
        </Page>
    );
}

export default CommandPage;
