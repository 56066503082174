import {combineReducers} from "@reduxjs/toolkit";
import {userReducer} from "../store/user/userSlice";
import {listRecuder} from "../store/lists/listSlice";
import {commandReducer} from "../store/user/commandSlice";


export const rootReducer = combineReducers({
    user: userReducer,
    list: listRecuder,
    command: commandReducer
})
