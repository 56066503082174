import {Link} from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import {Button, Form, Table, Typography} from "antd";
import {BASE_URL} from "../constant";
import axios from "axios";
import {useSelector} from "react-redux";
import {selectToken} from "../store/user/userSelector";
import {useState} from "react";

const { Title } = Typography;

function Db() {
    const token = useSelector(selectToken);
    const [tableData, setTableData] = useState([]);

    async function submitForm(e: any) {
        const value = e.target[0].value;
        const res = await axios.post(
            `${BASE_URL}/admin/query`,
            { query: value },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                }
            }
        );

        setTableData(res.data);
    }

    const columns = Object.entries(tableData[0] === undefined ? {} : tableData[0] as Object)?.map(([key,]) => {
        return {
            title: key,
            dataIndex: key,
            key: key
        }
    }) || {};

    return (
        <div className="container mx-auto p-4">
            <Link className="mb-5 underline text-blue-500" to={`/lists`}>
                &#8249; To Main Page
            </Link>

            <div className="flex justify-center items-center flex-col gap-3">
                <Title level={2}>Database Query</Title>
                <Form onSubmitCapture={submitForm} className="w-full max-w-2xl flex flex-col gap-3">
                    <TextArea name="query" id="query" rows={6} placeholder="Enter your SQL query here" />
                    <Button htmlType="submit" type="primary">Submit</Button>
                </Form>
            </div>
            {tableData.length !== 0 && (
                <Table dataSource={tableData} columns={columns} className="mt-5 w-full max-w-4xl" />
            )}
        </div>
    );
}

export default Db;
