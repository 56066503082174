import Page from "../components/Page";
import {Table} from "antd";
import {useEffect, useState} from "react";
import {get} from "../utils/httpUtils";

type User = {
    role: string;
    joined: boolean;
    vocabulary_id?: number;
    last_training_time?: number;
    profile_picture_url?: string;
    created_at?: number;
    description?: string;
    streak?: number;
    confirmed?: boolean;
    account_creation_type?: string;
    password?: string;
    full_name?: string;
    user_level?: string;
    id: number;
    banned: boolean;
    user_interface_language: string | null;
    max_streak: number;
    email: string;
};

function Users() {

    const [users, setUsers] = useState<User[]>([]);

    useEffect(() => {
        async function fetchData() {
            const res = await get("/admin/allUsers");

            setUsers(res);
        }

        fetchData().then(r => r)
    }, []);

    return (
        <Page pageHeader="Users">
            <Table dataSource={users} rowKey="id" className="min-w-[30rem]">
                <Table.Column title="ID" dataIndex="id"/>
                <Table.Column title="Email" dataIndex="email"/>
                <Table.Column title="Role" dataIndex="role"/>
                <Table.Column title="Full Name" dataIndex="full_name" />
            </Table>
        </Page>
    )
}

export default Users;
