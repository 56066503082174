import {Navigate} from "react-router-dom";
import WordDetails from "../pages/WordDetails";
import Login from "../pages/Login";
import AllWords from "../pages/AllWords";
import Db from "../pages/Db";
import CommandPage from "../pages/CommandPage";
import Users from "../pages/Users";
import SuggestedLists from "../pages/SuggestedLists";
import Words from "../pages/Words";
import React from "react";

export type ROUTE = {
    path: string,
    element: React.ReactNode
}

export const ROUTES: ROUTE[] = [
    {
        path: "/",
        element: <Navigate to="/login"/>
    },
    {
        path: "/wordDetails/:userVocabularyId",
        element: <WordDetails/>
    },
    {
        path: "login",
        element: <Login/>
    },
    {
        path: "allWords",
        element: <AllWords/>
    },
    {
        path: "db",
        element: <Db/>
    },
    {
        path: "command",
        element: <CommandPage/>
    },
    {
        path: "users",
        element: <Users/>
    },
    {
        path: "lists",
        element: <SuggestedLists/>
    },
    {
        path: "words/:groupId",
        element: <Words/>
    },

]
