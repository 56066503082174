import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

function Loading() {

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-black bg-opacity-50 fixed inset-0 z-40 "></div>
            <Spin
                size="large"
                className="z-50 text-green-600"
                indicator={<LoadingOutlined spin />}
            />
        </div>
    )
}

export default Loading;
